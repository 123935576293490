









































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'
import { downloadJSON } from '@/utils/fileUtils.ts'

@Component({
  methods: { appendSitePrefix, formatDateTimeString },
  components: { ListView },
})
export default class StockDeliveriesReport extends Vue {
  private loading = false

  private filters = {
    productId: null as string | null,
    fromDate: null as string | null,
    toDate: null as string | null,
  }

  private fromDateMenu = false
  private toDateMenu = false
  private exportJsonLoading = false
  private stockDeliveriesData = null

  private tableHeaders = [
    { text: this.$t('c:stock-details:Stock Account'), value: 'stockAccount' },
    { text: this.$t('c:stock-details:Cost Account'), value: 'costAccount' },
    { text: this.$t('c:stock-details:Cost Center Name'), value: 'costCenterName' },
    { text: this.$t('c:stock-details:Cost Center Code'), value: 'costCenterCode' },
    { text: this.$t('c:stock-details:Site ID'), value: 'siteId' },
    { text: this.$t('c:stock-details:Product ID'), value: 'productId' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Sum Amount'), value: 'sumAmount' },
    { text: this.$t('c:stock-details:Order ID'), value: 'orderId' },
    { text: this.$t('c:stock-details:Received At'), value: 'recievedAtDate' },
    { text: this.$t('c:stock-details:Supplier Name'), value: 'supplierName' },
  ]

  private executeReport() {
    this.loading = true
    this.$axios
      .get('/v4/site/stock/deliveries', { params: this.filters })
      .then((response) => {
        this.stockDeliveriesData = response.data.data
      })
      .catch((error) => {
        console.error('Error fetching report:', error)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private clear() {
    this.filters = {
      productId: null,
      fromDate: null,
      toDate: null,
    }
    this.fromDateMenu = false
    this.toDateMenu = false
    this.stockDeliveriesData = null
  }

  private handleJsonDownload() {
    downloadJSON(this.stockDeliveriesData, 'stock_deliveries', this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }
}
